<template>
  <div class="menu toolbox">
    <div><ToolboxButton text="Undo" icon="undo" :disabled="!canUndo" @click="mainStore.undo()" /></div>
    <div><ToolboxButton text="Redo" icon="redo" :disabled="!canRedo" @click="mainStore.redo()" /></div>
    <div><ToolboxButton text="Reset" icon="reset" @click="mainStore.resetRestructuring()" /></div>
    <div v-if="selectedItem">
      <div class="sep"></div>
      <div class="submenu-container" v-if="supported.restructuring">
        <div><ToolboxButton text="Fix" icon="fix" errorTooltip="We recommend you start with restructuring generalizations first." :disabled="!isGeneralization && modelContainsGeneralizations" :selected="showMultivalueAttributeMenu || showCompositeAttributeMenu || showGeneralizationMenu" @click="store.restructureItem()" /></div>
        <div class="submenu-align">
          <div class="submenu" v-if="showMultivalueAttributeMenu">
            <div><ToolboxButton text="Unique" icon="multi_attr_unique" @click="store.restructureMultivalueAttribute('unique')" /></div>
            <div><ToolboxButton text="Shared" icon="multi_attr_shared" @click="store.restructureMultivalueAttribute('shared')" /></div>
          </div>
        </div>
        <div class="submenu-align">
          <div class="submenu" v-if="showCompositeAttributeMenu">
            <div><ToolboxButton text="Split" icon="comp_attr_split" @click="store.restructureCompositeAttribute('split')" /></div>
            <div><ToolboxButton text="Merge" icon="comp_attr_merge" @click="store.restructureCompositeAttribute('merge')" /></div>
          </div>
        </div>
        <div class="submenu-align">
          <div class="submenu" v-if="showGeneralizationMenu">
            <div><ToolboxButton text="Collapse" icon="gen_collapse_parent" @click="store.restructureGeneralization('collapse_into_parent')" /></div>
            <div><ToolboxButton text="Collapse" icon="gen_collapse_children" :errorTooltip="allowed.collapseIntoChildren.error" :disabled="!allowed.collapseIntoChildren.ok" @click="store.restructureGeneralization('collapse_into_children')" /></div>
            <div><ToolboxButton text="Subst." icon="relationship" @click="store.restructureGeneralization('substitute')" /></div>
          </div>
        </div>
      </div>
      <div class="rename-container" v-if="supported.role">
        <ToolboxButton text="Role" icon="rename" :selected="showRenamingRoleInput" @click="store.toggleMenu('renamingRole')" />
        <div class="rename-input-container" v-if="showRenamingRoleInput">
          <input type="text" spellcheck="false" placeholder="Participation role" v-focusselect="showRenamingRoleInput" @input="renameRole($event)" @keydown="onRenameKeydown($event)" />
        </div>
      </div>
      <div class="rename-container" v-if="supported.renaming">
        <ToolboxButton text="Rename" icon="rename" :selected="showRenamingInput" @click="store.toggleMenu('renaming')" />
        <div class="rename-input-container" v-if="showRenamingInput">
          <input type="text" spellcheck="false" placeholder="New name" v-focusselect="showRenamingInput" @input="renameItem($event)" @keydown="onRenameKeydown($event)" />
        </div>
      </div>
    </div>
    <div class="sep"></div>
    <div><ToolboxButton text="Design" icon="design" @click="mainStore.backToDesign()" /></div>
    <div><ToolboxButton text="Translate" icon="logical" errorTooltip="Restructuring must be complete before proceeding with the translation." :disabled="!canTranslate" @click="mainStore.moveToTranslation()" /></div>
  </div>
</template>

<script>
import { computed } from 'vue';

import ToolboxButton from './ToolboxButton';

import Generalization from '../lib/generalization';
import { mainStore } from '../store/main';

export default {
  name: 'ERModelRestructToolbox',
  components: {
    ToolboxButton
  },
  directives: {
    focusselect: {
      mounted(el) {
        el.focus();
        el.select();
      }
    }
  },
  setup() {
    const erRestructuringStore = mainStore.erRestructuring;
    const canUndo = mainStore.$canUndo();
    const canRedo = mainStore.$canRedo();
    const selectedItem = erRestructuringStore.$selectedItem();
    const showRenamingInput = erRestructuringStore.$renamingInput();
    const showRenamingRoleInput = erRestructuringStore.$renamingRoleInput();
    const showMultivalueAttributeMenu = erRestructuringStore.$multivalueAttributeMenu();
    const showCompositeAttributeMenu = erRestructuringStore.$compositeAttributeMenu();
    const showGeneralizationMenu = erRestructuringStore.$generalizationMenu();
    const modelContainsGeneralizations = erRestructuringStore.$modelContainsGeneralizations();
    const canTranslate = erRestructuringStore.$canTranslate();

    const supported = computed(() => selectedItem.value.getSupportedFunctionalities());
    const allowed = computed(() => selectedItem.value.getAllowedFunctionalities());
    const isGeneralization = computed(() => selectedItem.value instanceof Generalization);

    function renameItem(e) {
      erRestructuringStore.renameItem(e.target.value);
      e.target.value = erRestructuringStore.getRenamingValue();
    }
    function renameRole(e) {
      erRestructuringStore.setRole(e.target.value);
      e.target.value = erRestructuringStore.getRenamingValue();
    }
    function onRenameKeydown(e) {
      if(e.which == 13 || e.which == 27)
        erRestructuringStore.toggleMenu();
    }
    
    return {
      store: erRestructuringStore,
      mainStore,
      canUndo,
      canRedo,
      selectedItem,
      showRenamingInput,
      showRenamingRoleInput,
      showMultivalueAttributeMenu,
      showCompositeAttributeMenu,
      showGeneralizationMenu,
      modelContainsGeneralizations,
      canTranslate,
      supported,
      allowed,
      isGeneralization,
      renameItem,
      renameRole,
      onRenameKeydown,
    };
  }
};
</script>