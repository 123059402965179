<template>
  <table v-if="erDesignStore.getSelectedItem().getTypeOfInPut()=='unary'" class="predicates">
    <thead>
      <tr>
        <th></th>
        <th class="my-th">Attribute</th>
        <th class="my-th">Operator</th>
        <th class="my-th">Condition</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>    
    </thead>
    <tbody v-if="erDesignStore.getSelectedItem().getTempAttrsName()">
      <tr v-if="allowForm()">
        <td></td>
        <td>
          <select id="attributes" name="attributes" @change="handleSelectedAttr($event)">
            <option value="" style="display:none"  disabled selected>select</option>            
            <option  v-for="i in erDesignStore.getSelectedItem().getTempAttrsName()" :key="i" >{{i}}</option>
          </select>
        </td>
        <td>
          <select id="operators" name="operators" @change="handleSelectedOp($event)">
            <option value="" style="display:none" disabled selected hidden>select</option>                
            <option >=</option>
            <option >&lt; &gt;</option>
            <option >&gt;</option>
            <option >&lt;</option>
            <option >≥</option>
            <option >≤</option>
            <option >is</option>
            <option >+</option>
            <option >-</option>
          </select>
        </td>
        <td><input class="my-input" id="input" type="text" placeholder="write your conditions" @input="handleCondition($event)"/></td>      
      </tr>
    </tbody>
    <tbody v-if="erDesignStore.getSelectedItem().getObjName()">
      <tr v-for="(i, index) in erDesignStore.getSelectedItem().getObjName()" :key="i.id">
        <td  v-if="index!=0">{{i.op}}</td> <td  v-else>&nbsp;</td>
        <td v-if="i.attrName!=''">{{i.attrName}}</td>
        <td v-if="i.operator!=''">{{i.operator}}</td>
        <td v-if="i.condition!=''">{{i.condition}}</td>
        <td v-if="i.attrName!=''"><button @click='handleDelete(i.id)'>x</button></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td v-if="allowAndOr()"><button  type='button' @click='handleAND($event)' >AND</button></td>
        <td v-if="allowAndOr()"><button type='button' @click='handleOR($event)' >OR</button></td>
      </tr>
    </tbody>
    <tbody>
      <tr></tr>
      <tr></tr>
      <tr v-if="allowSubmit() && allowToModify()">
        <button class="button-sub" type='button' @click='handleSubmit($event)'>Add</button>
      </tr>
    </tbody>
  </table> 
  <table v-else class="predicates">
    <thead>
      <tr>
        <td></td>
        <th class="my-th">Attribute</th>
        <th class="my-th">Operator</th>
        <th class="my-th">Attribute</th>
        <th></th>
        <th></th>
        <th></th>
      </tr>    
    </thead>  
    <tbody v-if="erDesignStore.getSelectedItem().getTempAttrsName()" >
      <tr v-if="allowForm()">
        <td></td>
        <td v-if="allowExtendedOptions() && isEntity()"><select class="selected" id="attributes" name="attributes" @change="handleSelectedAttr($event)">
          <option value="" style="display:none"  disabled selected>select</option>     
          <option  v-for="i in erDesignStore.getSelectedItem().getTempAttrsName()" :key="i" >{{i}}</option>
          <option v-for="i in erDesignStore.getSelectedItem().getParticipations()[1].getEntity().getAttributes().map(e => e.getName())" :key="i" >{{i}}</option>
          </select>
        </td>
        <td v-else-if="allowExtendedOptions() && !isEntity()"><select class="selected" id="attributes" name="attributes" @change="handleSelectedAttr($event)">
          <option value="" style="display:none"  disabled selected>select</option>     
          <option  v-for="i in erDesignStore.getSelectedItem().getTempAttrsName()" :key="i" >{{i}}</option>
          <option v-for="i in erDesignStore.getSelectedItem().getParticipations()[1].getEntity().getTempAttrs()" :key="i" >{{i}}</option>
          </select>
        </td>
        <td v-else><select class="selected" id="attributes" name="attributes" @change="handleSelectedAttr($event)">
          <option value="" style="display:none"  disabled selected>select</option>     
          <option  v-for="i in erDesignStore.getSelectedItem().getTempAttrsName()" :key="i" >{{i}}</option>
          </select>
        </td>
        <td><select class="selected" id="operators" name="operators" @change="handleSelectedOp($event)">
            <option value="" style="display:none" disabled selected hidden>select</option>                
            <option >=</option>
            <option >&lt; &gt;</option>
            <option >&lt;</option>
            <option >&gt;</option>
            <option >≥</option>
            <option >≤</option>
            </select></td>
         <td v-if="allowExtendedOptions() && isEntity()"><select class="selected" id="attributes" name="attributes" @change="handleSelectedAttr1($event)">
            <option value="" style="display:none"  disabled selected>select</option>     
            <option  v-for="i in erDesignStore.getSelectedItem().getTempAttrsName()" :key="i" >{{i}}</option>
            <option v-for="i in erDesignStore.getSelectedItem().getParticipations()[1].getEntity().getAttributes().map(e => e.getName())" :key="i" >{{i}}</option>
            </select>
          </td>
          <td v-else-if="allowExtendedOptions() && !isEntity()"><select class="selected" id="attributes" name="attributes" @change="handleSelectedAttr1($event)">
            <option value="" style="display:none"  disabled selected>select</option>     
            <option  v-for="i in erDesignStore.getSelectedItem().getTempAttrsName()" :key="i" >{{i}}</option>
            <option v-for="i in erDesignStore.getSelectedItem().getParticipations()[1].getEntity().getTempAttrs()" :key="i" >{{i}}</option>
            </select>    
          </td>
          <td v-else><select class="selected" id="attributes" name="attributes" @change="handleSelectedAttr1($event)">
            <option value="" style="display:none"  disabled selected>select</option>     
            <option  v-for="i in erDesignStore.getSelectedItem().getTempAttrsName()" :key="i" >{{i}}</option>
            </select>
          </td>
        </tr>
       </tbody>
       <tbody  v-if="erDesignStore.getSelectedItem().getObjName()">
        <tr  v-for="(i, index) in erDesignStore.getSelectedItem().getObjName()" :key="i.attrName">
          <td  v-if="index!=0">{{i.op}}</td> <td  v-else>&nbsp;</td>
          <td v-if="i.attrName!=''">{{i.attrName}}</td>
          <td v-if="i.operator!=''">{{i.operator}}</td>
          <td v-if="i.attrName1!=''">{{i.attrName1}}</td>
          <td></td>
          <td v-if="i.attrName!=''"><button @click='handleDelete(i.id)'>x</button></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td v-if="allowAndOr()"><button  type='button' @click='handleAND($event)' >AND</button></td>
          <td v-if="allowAndOr()"><button type='button' @click='handleOR($event)' >OR</button></td>
        </tr>
      </tbody>
      <tbody>
        <tr></tr>
        <tr></tr>
        <tr v-if="allowSubmit() && allowToModify()">
          <button class="button-sub" type='button' @click='handleSubmit($event)'>Add</button>
        </tr>
      </tbody>    
  </table>  
</template>

<script>
import { mainStore } from '../store/main';
import Model from '../lib/model'
import ItemType from './ItemType'

export default {
   name: 'Predicates',
   components: {
    //
  },
  data() {
    return {
      model: new Model(),
      submitted: false,
      predicate: {id: 0, attrName: '', operator:'', attrName1: '', condition: '', op: ''},
      predicates: this.handleInit(), 
      pconcat: '',
      firstLine: true,
      firstLineB: true,
      or: false,
      and: false,
      toModify: false,
      allDeleted: false,
    }
  },
  methods: {
    handleAND(e){ 
      e.preventDefault(); 
      this.allDeleted=false;
      this.submitted=false;
      if(this.getSelectedItem().getObjName()){
        this.predicates=this.getSelectedItem().getObjName();
      }
      this.predicate.op='AND';
      if(this.getSelectedItem().getToModify()==true){
        this.getSelectedItem().setToModify(false);
      }
    },
    handleOR(e){ 
      this.allDeleted=false;
      e.preventDefault(); 
      this.submitted=false;
      if(this.getSelectedItem().getObjName()){
        this.predicates=this.getSelectedItem().getObjName();
      }     
      this.predicate.op='OR'; 
      if(this.getSelectedItem().getToModify()==true){
        this.getSelectedItem().setToModify(false);
      }
    },
    handleSubmit(e){ 
      this.allDeleted=false;
      e.preventDefault(); 
      if( this.getSelectedItem().getTypeOfInPut()=='unary' && (this.predicate.attrName=='' || this.predicate.operator=='' || this.predicate.condition=='')){
        return;
      }
      else if(this.getSelectedItem().getTypeOfInPut()!='unary' && (this.predicate.attrName=='' || this.predicate.operator=='' || this.predicate.attrName1=='')){
        return;
      }
      else{
        this.firstLine=false;
        let myid;
        if(this.getSelectedItem().getObjName() && this.erDesignStore.getSelectedItem().getObjName()!=''){
          myid=this.getSelectedItem().getObjName()[this.getSelectedItem().getObjName().length-1].id
        }
        else {
          myid=0;
        }
        myid++;
        this.predicates.push(new ItemType(myid, this.predicate.attrName , this.predicate.operator, this.predicate.attrName1, this.predicate.condition, this.predicate.op));
        this.erDesignStore.getSelectedItem().setObjName(this.predicates); 
        this.predicate.attrName='';
        this.predicate.operator='';
        this.predicate.attrName1='';
        this.predicate.condition='';
        this.predicate.op='';
        document.getElementById("attributes").value="";
        document.getElementById("operators").value="";
        document.getElementById("input")!=null ? document.getElementById("input").value="" : '';        
        this.submitted=true;
        if(this.getSelectedItem().getToModify()==true){
        this.getSelectedItem().setToModify(false);
        }
      }
    },
      handleSelectedAttr(e){
        this.predicate.attrName=e.target.value;
      },
      handleSelectedAttr1(e){
        this.predicate.attrName1=e.target.value;
      },
      handleSelectedOp(e){
        this.predicate.operator=e.target.value;
      },
      handleCondition(e){
        this.predicate.condition=e.target.value;
      },      
      handleDelete(id){
        if(this.predicates){
          this.predicates = this.predicates.filter(p => p.id !== id);
          this.erDesignStore.getSelectedItem().setObjName(this.predicates);
          this.$forceUpdate();         
          if(this.predicates=='' || (this.erDesignStore.getSelectedItem().getObjName()[0]=='' ||this.erDesignStore.getSelectedItem().getObjName()=='' ||this.erDesignStore.getSelectedItem().getObjName()==[]  )){
           this.allDeleted=true;
          }
        }
        else {
          let temp= this.erDesignStore.getSelectedItem().getObjName().filter(p => p.id !== id);
          this.erDesignStore.getSelectedItem().setObjName(temp);
          this.$forceUpdate();
        }
        return;
      },
      allowAndOr(){
        if(this.erDesignStore.getSelectedItem().getObjName()=='')
          return false;
        if(this.allDeleted==true)
          return false;
        if(this.getSelectedItem().getToModify()==true)
          return true;        
        if(this.firstLine == true)
          return false;
        else if(this.submitted == true)
          return true;
        else return false;
      },
      allowForm(){
        if(this.erDesignStore.getSelectedItem().getObjName()=='')
          return true;
        if(this.allDeleted==true)
          return true;
        if(this.getSelectedItem().getToModify()==true)
          return false; 
        if(this.firstLine == true)
          return true;
        else if(this.submitted == false && !this.allowAndOr())
          return true;
        return false;
      },
      allowSubmit(){
        if(this.erDesignStore.getSelectedItem().getObjName()=='')
          return true;
        if(this.allDeleted==true)
          return true;
        if(this.getSelectedItem().getToModify()==true)
          return false;        
        if(this.firstLine == true)
          return true;
        else if(this.submitted==true) 
          return false;        
        else return true;
      },
      allowToModify(){        
        if ( this.toModify==true){
          this.toModify=false;
          return false;
        }
        else 
          return true;
      },
      allowExtendedOptions(){
        if(this.getSelectedItem().getTypeOfInPut()=='semi-join' || this.getSelectedItem().getTypeOfInPut()=='asemi-join')
          return true;
        else return false;
      },
      isEntity(){
        if(this.getSelectedItem().getParticipations()[1].getEntity().isEntity())
          return true;
        else return false;
      }    
   },
  
  setup() {
    const erDesignStore = mainStore.erDesign;

      function getList(){
        this.items=erDesignStore.getSelectedItem().getTempAttrsName();
        return this.items;
      }
      function setAttrs(){
        for(let i in erDesignStore.getSelectedItem().getTempAttrsName()){
            if(!this.items.some(s=>s==erDesignStore.getSelectedItem().getTempAttrsName()[i])){
                erDesignStore.getSelectedItem().getTempAttrsName().pop(erDesignStore.getSelectedItem().getTempAttrsName()[i]);
            }
        }      
      }
      function getSelectedItem(){
        return erDesignStore.getSelectedItem();
      }
      function handleInit(){
        if(this.getSelectedItem().getObjName() && this.allDeleted!=true)
          this.toModify=true;
        else 
          this.toModify=false;
        return this.predicates =this.getSelectedItem().getObjName() ? this.getSelectedItem().getObjName() : [];
      }
      
    return { 
      erDesignStore,
      getList,
      setAttrs,
      getSelectedItem,
      handleInit, 
      }
  },

   
   
   
};
</script>

<style>
.my-body{
  font-size: 16px !important;
  padding: 5px !important;
  width: 200px !important; 
}
  

.predicates {
  position: absolute;
  left: -10px;
  top: -12px;
  
  background-color: #f0f0f0; 
  padding: 5px; 
  border-radius: 4px; 
  border-spacing: 0 5px;   
   box-shadow: transparent !important;
  width: 492px;
}
th{
  font-family: 'Commissioner', sans-serif;
  font-size: 14px;
  font-weight: normal;

}

 td, th{
    padding-inline: 6px;
    position: relative;
    right: 50 px;
    text-anchor: middle;
     

  }
  tr{
    font-family: 'Commissioner', sans-serif;
    font-size: 13px;    
  }
  #userText{
    width:130px;
    height: 13px;
  }
  .button-add-p{
  font-family: 'Commissioner', sans-serif;
  background: #e6af2e;
  background-color: #e6af2e;
  border: #e6af2e;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 400;
  position: relative ;
  left: 0px !important;

 
  }
  .button-submit{
  position: relative;
  left: 60px;
  
}
.separator{
  height: 1px;
  margin: 6px 0;
  border-bottom: 1px dashed #ccc;
}

select{
    padding: 2px;
    border-color: #f0f0f0;
    height: 26px;
    margin: 2px;
    border-radius: 6px;
  font-family: 'Commissioner', sans-serif;
  box-shadow: transparent !important;
}
option{
    border-radius: 15px;
    background-color: #f0f0f0;
     box-shadow: transparent !important;
    
}
.my-input{
  background-color: white !important;
  border-radius: 6px;
   box-shadow: transparent !important;
}
.btn1{
margin-right: 0px;
}
.btn2{

margin-left: 0px;

}
option:first {
    color: #999;
}
.button-sub{
  position: relative;
  left: 420px;
}
.predicate-table{
  width: 400px;
}
.my-th{
  text-align: left !important;
}


/*.select:hover::after {
        color: black !important;
    }
option:checked {
    color: red !important;
    background-color: black !important;
    
}
select:focus option:checked{
  color: red !important;
    background-color: black !important;
}
option:hover*/

/*select option:hover, 
select option:focus, 
select option:active, 
select option:checked
{
    background: linear-gradient(#FFC894,#FFC894);
    background-color: #FFC894 !important; /* for IE */

</style>

