<template>
  <DiagramEditor contextName="erRestructuring" :restructuring="true" :store="store">
    <ERModelRestructToolbox />
    <ErrorStatusBar :errors="selectedItemErrors" :warnings="selectedItemWarnings" />
  </DiagramEditor>
</template>

<script>
import DiagramEditor from './DiagramEditor';
import ERModelRestructToolbox from './ERModelRestructToolbox';
import ErrorStatusBar from './ErrorStatusBar';
import { getContext } from '../lib/editor';
import { mainStore } from '../store/main';

export default {
  name: 'ERModelRestruct',
  components: {
    DiagramEditor,
    ERModelRestructToolbox,
    ErrorStatusBar
  },
  setup() {
    const store = mainStore.erRestructuring;
    const selectedItemWarnings = store.$selectedItemWarnings();
    const selectedItemErrors = store.$selectedItemErrors();
    
    getContext('erRestructuring').setCallbacks({
      undo: () => mainStore.undo(),
      redo: () => mainStore.redo(),
      selectItem: id => store.selectItem(id),
      moveItem: (dx, dy) => store.moveItem(dx, dy),
      endMoveItem: () => store.finishedMovingItem()
    });

    return {
      store,
      selectedItemWarnings,
      selectedItemErrors
    };
  }
};
</script>