import { QUADRANT, radians, normalizeAngle, pointAtDistance, rectQuadrantAbs, diamondProjection, rectProjection, roundRectProjection, multiPointCurve } from './geometry';
import { getContext } from '../editor';

const ctx = getContext('utils');
ctx.canvasUpdated(document.createElement('div'));

/**
 * Find the coordinates of the centroid of the given @param items
 */
export const centroid = items => {
  let x = 0, y = 0;
  for(let item of items) {
    x += item.getX() / items.length;
    y += item.getY() / items.length;
  }
  return { x, y };
};
/**
 * Find the best coordinates to accomodate an attribute in the given @param item
 */

/**
 * Find coordinates for relationship and entity substituting a multivalued attribute
 */

/**
 * Find an angle where an attribute can be accomodated, taking into account @param busy spots
 */

/**
 * Find the best position in the given @param _attribute where a subattribute can be accomodated
 */

/**
 * Find the best position in the given @param _entity where an attribute can be accomodated
 */

/**
 * Find the best position in the given @param _relationship where an attribute can be accomodated
 */

/**
 * Points defining the segment from an entity shape to a relationship shape
 */
export const participationSegment = (_entity, _relationship, recursiveRelationship) => {
  let source = { x: null, y: null }, dest = { x: null, y: null };
  const entityRect = _entity.getShape(), relationshipDiamond = _relationship.getShape();
  if(recursiveRelationship != null) {
    [dest.x, dest.y] = [_relationship.translation.x, _relationship.translation.y];
    let quadrant = rectQuadrantAbs(dest.x, dest.y, entityRect);
    if(quadrant == QUADRANT.TOP || quadrant == QUADRANT.BOTTOM)
      dest.x += (recursiveRelationship ? -0.5 : 0.5) * relationshipDiamond.width;
    else if(quadrant == QUADRANT.LEFT || quadrant == QUADRANT.RIGHT)
      dest.y += (recursiveRelationship ? -0.5 : 0.5) * relationshipDiamond.height;
  } else {
    [dest.x, dest.y] = diamondProjection(_entity.translation.x, _entity.translation.y, relationshipDiamond);
  }

  [source.x, source.y] = rectProjection(dest.x, dest.y, entityRect);
  return [source.x, source.y, dest.x, dest.y];
};
/**
 * Points defining the segment between two table shapes
 */
export const foreignKeySegment = (_tableA, _tableB, indexA, indexB, dist) => {
  let aPos, bPos;
  const tableARect = _tableA.getShape(), tableBRect = _tableB.getShape();

  if(tableARect.x < tableBRect.x) {
    aPos = 1;
    bPos = -1;
  } else {
    aPos = -1;
    bPos = 1;
  }
  const x1 = tableARect.x + aPos * tableARect.width / 2;
  const y1 = tableARect.y - tableARect.height / 2 + 94 + 28 * indexA;
  const x2 = x1 + aPos * dist;
  const y3 = tableBRect.y - tableBRect.height / 2 + 94 + 28 * indexB;
  const x4 = tableBRect.x + bPos * tableBRect.width / 2;
  const x3 = x4 + bPos * dist;

  return [x1, y1, x2, x3, y3, x4];
};
/**
 * Curve to render the multiple identifier graphically
 */
export const multipleIdentifiersCurve = (attributes, dist, deltaAngle) => {
  return multiPointCurve(attributes.map(a => {
    const [x, y] = pointAtDistance(a.getX(), a.getY(), 0, 0, dist);
    return { x, y };
  }), deltaAngle);
};
/**
 * Graphic objects to render the external identifier graphically
 */
export const externalIdentifierCurve = (_entity, _relationships, attributes, dist, deltaAngle, radius) => {
  const rect = _entity.getShape();
  const roundRect = { x: rect.x, y: rect.y, width: rect.width + dist * 2, height: rect.height + dist * 2, radius };
  const outerCircleRadius = Math.sqrt(roundRect.width * roundRect.width / 4 + roundRect.height * roundRect.height / 4);

  let angles = attributes.map(a => normalizeAngle(Math.atan2(-a.getY(), a.getX()))).concat(_relationships.map(r => normalizeAngle(Math.atan2(rect.y - r.getShape().y, r.getShape().x - rect.x))));
  angles.sort((a, b) => Math.min(Math.abs(a - Math.PI / 2), Math.abs(a - Math.PI * 5/2)) - Math.min(Math.abs(b - Math.PI / 2), Math.abs(b - Math.PI * 5/2)));
  const clockwise = angles.filter(a => a <= (Math.PI * 2 / 3) || a >= (Math.PI * 3 / 2)).length > angles.filter(a => a >= (Math.PI / 3) && a <= (Math.PI * 3 / 2)).length;
  const firstAngle = angles[0] + (clockwise ? 1 : -1) * (angles.length > 1 ? 2 : 3) * radians(deltaAngle);
  const distFromFirstAngle = angles.map(a => {
    let u = ((angles[0] - a) * (clockwise ? 1 : -1));
    if(u < 0)
      u += 2 * Math.PI;
    return u;
  });
  const lastAngle = angles[distFromFirstAngle.indexOf(Math.max(...distFromFirstAngle))] + (clockwise ? -1.25 : 1.25) * radians(deltaAngle);

  let points = [];
  const sortedAngles = [...angles.sort(), firstAngle];
  for(let angle of sortedAngles) {
    const rx = Math.cos(angle) * outerCircleRadius, ry = Math.sin(angle) * outerCircleRadius;
    const [x, y] = roundRectProjection(roundRect.x + rx, roundRect.y - ry, roundRect);
    points.push(x - rect.x, y - rect.y);
  }
  let sa = 2 * Math.PI, ea = 2 * Math.PI;
  if(clockwise) {
    sa -= (firstAngle + (firstAngle < lastAngle ? 2 * Math.PI : 0));
    ea -= lastAngle;
  } else {
    sa -= firstAngle;
    ea -= (lastAngle + (lastAngle < firstAngle ? 2 * Math.PI : 0));
  }
  return [roundRect, outerCircleRadius, sa, ea, points];
};