<template>
  <table class="my-table">
    <thead>
      <tr>
        <th>Attribute</th>
        <th style="width:300">PK</th>
        <th>NULL</th>
        <th>Delete</th>
      </tr>        
    </thead>
      <tbody v-if="erDesignStore.getSelectedItem()">
        <tr v-for="i in  erDesignStore.getSelectedItem().getAttributes()" :key="i.getId()">
        <td>{{i.getName()}}</td>
        <td>{{i.getPrimaryKey()}}</td>
        <td>{{i.getNullAttr()}}</td>
        <td><button @click='handleDelete(i.getId())'>x</button></td></tr>
      </tbody> 
      <tbody>
        <tr>
          <td><input type="text" ref='search'  id="userText" @input='handleAttrName($event)'/></td>
          <td><input type="checkbox" id="mycheck" :disabled="this.item.nullAttr" @input='handlePrimaryKey($event)'/></td>
          <td><input type="checkbox" id="mycheck" :disabled="this.item.primaryKey"  @input='handleNull($event)'   /></td>
          <!--:disabled="checkPrimaryKey()" -->
        </tr>
      </tbody>
      <tbody>
        <tr><th></th></tr>
        <button class="button-add" type='button' @click='handleAdd($event)'>Add</button>
      </tbody>
  </table>  
</template>

<script>
//import Attribute from '../lib/attribute';

import { mainStore } from '../store/main';
import Model from '../lib/model'
//import Attribute from '../lib/attribute'
export default {
   name: 'RATableSchema',
   components: {
    //
  },
  mounted(){
       this.$refs.search.focus();
   },
  data() {
  return {
      model: new Model(),
      item:  {attrName: '', primaryKey: false, nullAttr: false},
      items: []
      }      
    },
  methods: {
    checkPrimaryKey(){
      if(this.item.primaryKey!='')
        return this.item.primaryKey;
      else
        return false;
      },
    handleAttrName(e){
      this.item.attrName=e.target.value;
    },
    handlePrimaryKey(e){
      this.item.primaryKey=e.target.checked;
      //e.taget ? disableNull=e.target.checked : false;
    },
    handleNull(e){
      this.item.nullAttr=e.target.checked;
    },
    handleAdd(e){ 
      e.preventDefault();   
      if(this.item.primaryKey=='')
          this.item.primaryKey=false;
      if(this.item.nullAttr=='')
          this.item.nullAttr=false;
      if(this.item.attrName=='')
        return;
        // this.item.id=count;
        //new ItemAttr(count, this.item.attrName, this.item.primaryKey, this.item.handleNull);
        //this.items.push(item);
        //this.items.pop(item);
        //this.itemsUpdated();
        //console.log(this.items);
        //this.item.attrName='';
        //this.item.nullAttr='';
        //this.item.primaryKey='';
        //this.itemsUpdated();        
        //new Attribute(this.getSelectedItem().getName()+"."+this.item.attrName, this.getSelectedItem().getId(), this.item.primaryKey, this.item.nullAttr);
        this.erDesignStore.addAttribute(this.getSelectedItem().getName()+"."+this.item.attrName, this.item.primaryKey, this.item.nullAttr);
        //console.log(this.erDesignStore.getSelectedItem().getAttributes());
        document.getElementById("userText").value="";
        this.item.attrName="";
        this.item.primaryKey=false;
        this.item.nullAttr=false;
        document.getElementById("mycheck").checked=false;
        this.$forceUpdate();
      },
    handleDelete(id){
      this.erDesignStore.deleteAttribute(id);  
      this.$forceUpdate(); 
    }
  },
  setup() {
    const erDesignStore = mainStore.erDesign;
    //let item = {id: 0, attrName: '', primaryKey: false, nullAttr: false};
    //let disableNull = false;

    /*function handleAttrName(e){
        item.attrName=e.target.value;
      }
    function handlePrimaryKey(e){
        item.primaryKey=e.target.checked;
        e.taget ? disableNull=e.target.checked : false;
      }
     function handleNull(e){
        item.nullAttr=e.target.checked;
      }
      function handleAdd(){      
        if(item.primaryKey=='')
            item.primaryKey=false;
        if(item.nullAttr=='')
            item.nullAttr=false;
        if(item.attrName=='')
            return;
        //this.item.id=count;
        //new ItemAttr(count, this.item.attrName, this.item.primaryKey, this.item.handleNull);
        //this.items.push(item);
        //this.items.pop(item);
        //this.itemsUpdated();
        //this.item.attrName='';
        //this.item.nullAttr='';
        //this.item.primaryKey='';
        //this.itemsUpdated();
        erDesignStore.addAttribute(item.attrName, item.primaryKey, item.nullAttr);
      }*/
    function getSelectedItem(){
      return erDesignStore.getSelectedItem();
    }
  return { 
    erDesignStore,
    getSelectedItem,
    }
  },   
};
</script>

<style>
.my-table {
  position: absolute;
  left: 77px;
  top: 210px;
  
  background-color: #f0f0f0; 
  padding: 5px; 
  border-radius: 4px; 
  border-spacing: 0 5px;   
}
th{
  font-family: 'Commissioner', sans-serif;
  font-size: 14px;
  font-weight: normal;

}

 td, th{
    padding-inline: 6px;
    position: relative;
    right: 50 px;
    text-anchor: middle;
     

  }
  tr{
    font-family: 'Commissioner', sans-serif;
    font-size: 13px;
    
  }
  #userText{
    width:130px;
    height: 13px;
  }
  button{
  font-family: 'Commissioner', sans-serif;
  background: #e6af2e;
  background-color: #e6af2e;
  border: #e6af2e;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 400;
  }
.button-add{
  position: relative;
  left: 240px;
}
.separator{
  height: 1px;
  margin: 6px 0;
  border-bottom: 1px dashed #ccc;
} 
 /*
 tabella misure:
  position: relative;
  left: 72px;
  top:-60px;
  position: absolute;
  left: 77px;
  top: 210px; 
 table {
  //assolutam
  position: relative;
  left: 72px;
  top:-70px;
  background-color: #f0f0f0; 
  padding: 5px;
  border-radius: 10px; 
}
/*th,tr, td{
  box-shadow: 0 0 1px rgb(34 25 25 / 40%);
  align-content: center;
  width: 400px;
  border-collapse: collapse;
  border-radius: 4px;
  border-style: solid;
}*/
/*table, th, td, tr{  
  border-collapse: collapse;
  border-radius: 4px;
  border-style: solid;
}

tr{
  height: 50px;
}
th, td {
  width: 50%;
}
tr{
  border-bottom: 2px solid
}
*/
/*
td, th{
    padding-inline: 6px;
    position: relative;
    right: 50 px;
    text-anchor: middle;  
}
td{
  align-items: center;
  align-self: center;
  align-content: center;
}

input[type=checkbox] {
  margin: 5px 20px;
  position: relative;
  border: 1px solid black;
  border-radius: 5px;
  height: 19px ;
  width: 19px;
  background: #f0f0f0;
  accent-color: #f0f0f0;
  }
  
.button-add{
  position: relative;
  left: 0px;
  background: #e6af2e;
  background-color: #e6af2e;
  border: #e6af2e; 
}*/

</style>

<!-- 
data(){
    return{
        fields: [
          {key: 'attrName'},
          {key: 'primaryKey'},
          {key: 'nullAttr'},
        ],
        items: [],
        added: false
    }
   },
    methods: {  
    }
input[type=checkbox] {
  margin: 5px 20px;
  position: relative;
  border: 1px solid #555;
  border-radius: 5px;
  width: 50px;
  height: 16px ;
  }

  input[type=text] {
  border: 1px solid #555;
  border-radius: 5px;
  background-color: #f0f0f0;
}
-->
