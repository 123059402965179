//import Attribute from './attribute';
import Entity from './entity';
import Participation from './participation';


//questa è la definizione di input GENERICO
export default class Relationship {
  constructor(model, id,  name, x, y, typeOfInput, temporaryAttrs, outer, inner, objnName) {
    this.__type = 'Relationship';
    this._model = () => model;
    this._id = id;
    this._name= name;
    this._objname= objnName;
    this._x = Math.round(x);
    this._y = Math.round(y);
    this._typeOfInput= typeOfInput;
    this._participationCount=0;
    this._temporaryAttrs = temporaryAttrs;
    this._objTemporaryAttrs;
    this._outer=outer;
    this.submitted=false;
    this._precTempAttrs;
    this._toModify=false;
    this._inner = inner;
  }
  static fromObject(model, obj) {
    return new Relationship(model, obj._id, obj._name, obj._x, obj._y, obj._typeOfInput, obj._temporaryAttrs, obj._outer, obj.submitted, obj._objname, obj._precTempAttrs, obj._toModify, obj._participationCount);
  }
  getId() {
    return this._id;
  }
  getName() {
    return this._name;
  }
  getObjName() {
    return this._objname;
  }
  getObjNameAttr() {
    return this._objname.attrName;
  }
  getObjNameAttr1() {
    return this._objname.attrName;
  }
  getX() {
    return this._x;
  }
  getY() {
    return this._y;
  }
  getTypeOfInPut() {
    return this._typeOfInput;
  }
  getOuter() {
    return this._outer;
  }
  
  setSubmitted(){
    return this.submitted=true;
  }
  getSubmitted(){
    return this.submitted;
  }
  getToModify(){
    return this._toModify;
  }
  getPrecTempAttrs(){
    return this._precTempAttrs;
  }
  getTypeToDraw(){
    let draw;
    switch(this._typeOfInput){
      case 'unary': 
        draw='σ';
      break;
      case 'result': 
        draw='R';
      break;
      case 'unaryPi': 
        draw='π';
      break;
      case 'binary': 
        draw='⋈';
      break;
      case 'naturalJoin': 
        draw='⋈';
      break;
      case 'semi-join': 
        draw='⋉';
      break;
      case 'asemi-join': 
        draw='⋉';
      break;
      case 'difference': 
        draw='—';
      break;
      case 'division': 
        draw='/';
      break;
      case 'union': 
        draw='∪';
      break;
      case 'intersection': 
        draw='ᑎ';
      break;
    } 
    return draw;
  }
  getParticipationCount() {
    return this._participationCount;
  }
  getTempAttrs() {
    return this._temporaryAttrs;
  }
  getObjTempAttrs() {
    return this._objTemporaryAttrs;
  }
  getTempAttrsName() {
    if(this._typeOfInput=='unary')
      return this._temporaryAttrs;

    else if(this._typeOfInput=='difference' ||this._typeOfInput=='union' || this._typeOfInput=='intersection' ){
      return this._temporaryAttrs.map(e=>e.getName());
    }
    else if(this._typeOfInput=='unaryPi'){
      if(this._typeOfInput=='unaryPi'  && this._temporaryAttrs[0] && this._temporaryAttrs[0].__type=='Attribute'){
       // console.log('faccio il map');
      return this._temporaryAttrs.map(e=>e.getName());
    }
    else {
      //console.log('non mappo');
      return this._temporaryAttrs;
    }
  }
    else return this._temporaryAttrs;
    
  }
  setTempAttrs(attrs) {
    return this._temporaryAttrs=attrs;
  }
  setObjTempAttrs(attrs) {
    return this._objTemporaryAttrs=attrs;
  }
  setParticipationCount(count) {
    return this._participationCount=count;
  }
  increaseParticipationCount(){
    return this._participationCount++;
  }
  decreaseParticipationCount(){
    return this._participationCount--;
  }
  setShowResultTrue(){
    return this.showResult={ ok: true };
  }
  setToModify(s){
    return this._toModify=s;
  }
  getParticipations() {
    return this._model().getItemsWhere(i => i instanceof Participation && i.getRelationship().getId() == this.getId());
  }
  hasParticipations() {
    return this.getParticipations().length;
  }
  isRecursive() {
    return this.getParticipations().length == 2 && new Set(this.getParticipations().map(p => p.getGenericET())).size == 1;
  }
  isTernary() {
    return this.getParticipations().length == 3;
  }
  isManyToMany() {
    return this.getParticipations().length == 2 && this.getParticipations().map(p => p.getMaxCardinality()).every(c => c == 'N');
  }
  isOneToMany() {
    return this.getParticipations().length == 2 && this.getParticipations().map(p => p.getMaxCardinality()).sort().join('_') == '1_N';
  }
  isOneToOne() {
    return this.getParticipations().length == 2 && this.getParticipations().map(p => p.getMaxCardinality()).every(c => c == '1');
  }

  isEntity(){
    return false;
  }
  isRelationship(){
    return true;
  }

  setPrecTempAttrs(t){
    return this._precTempAttrs=t;
  }
  setTemporary(entity){
    let temp=[];
    let isEntity=false; 

    let innerj;
    let outerj; 

    if(entity instanceof Entity){
      isEntity = true;
      if(this.getParticipations().length==0){
        this._outer=entity.getAttributes() ? entity.getAttributes().map(e=>e.getName()) : '';   

       }
       else{      
        innerj=this.getParticipations()[0].getRelationship().getOuter().map(e=>e); //attributi outer
        outerj=entity.getAttributes() ? entity.getAttributes().map(e=>e.getName()) : ''; //attributi inner      
    }
    }
    else{
      
      if(this.getParticipations().length==0){
        this._outer=entity.getTempAttrs();   
      }
       else{      
        innerj=this.getParticipations()[0].getRelationship().getOuter().map(e=>e); //attributi outer
        entity.getTypeOfInPut()=='unaryPi' ? outerj= entity.getTempAttrs().map(e=>e) : outerj= entity.getTempAttrs(); //attributi inner
    }
    }    
    
    switch(this._typeOfInput){ //è l'input della rel
      
      case 'unary': //select
        //input ? output.setTempAttrs(input) : '';
        //console.log('select');
        isEntity ? this.setTempAttrs(entity.getAttributes().map(e=>e.getName())): this.setTempAttrs(entity.getTempAttrs().map(e=>e.getName())) ;
       // console.log(this.getTempAttrs());
      break;
      case 'unaryPi': //pi   
      isEntity ? this.setTempAttrs(entity.getAttributes()) : this.setTempAttrs(entity.getTempAttrs());
      isEntity ? this.setPrecTempAttrs(entity.getAttributes()) : this.setPrecTempAttrs(entity.getTempAttrs());
      this._objTemporaryAttrs= this._outer.map( a => ({_name: a, selected: false}));
      break;
      case 'binary': //thehta
      //unione
      if(!this.getParticipations().length==0) {
        innerj=innerj.concat(outerj);
        this.setTempAttrs(innerj);
       } 
      break;
      case 'naturalJoin':
        //unione + elimino i duplicati
        if(innerj!='' || outerj!=''){
          if(!this.getParticipations().length==0) {
            innerj=innerj.concat(outerj);         
            let temp =[];
            let tableNameo= innerj[0] ? innerj[0].split('.')[0] : '';
            //innerj.map(e=>e[0].split('.')[0])
            let tableNamei=outerj[0] ? outerj[0].split('.')[0] : '';
            for (let i in innerj){          
              innerj[i].split('.') ? temp= temp.concat(innerj[i].split('.')[1]) : temp= temp.concat(innerj[i].map(e=>e.getName().split('.')[1]));
            }
            innerj= Array.from(new Set(temp)); //funziona
            temp=[];
            //console.log(outerj);
            for (let i in innerj){          
              //console.log(outerj.map(e => e.split('.')[1]).indexOf(innerj[i]));
              if (outerj.map(e => e.split('.')[1]).indexOf(innerj[i])!=-1){
                temp=temp.concat(tableNamei+'.'+ innerj[i]);
              }
              else{
                temp=temp.concat(tableNameo+'.'+ innerj[i]);
              }
            }
          this.setTempAttrs(temp);
        }
      }
        else {
          this.setTempAttrs(temp);
        }
     
      break;
      case 'semi-join': 
        //A semij B solo lo schema di A
        if(!this.getParticipations().length==0) {
          this.setTempAttrs(innerj);
        }
      break;
      case 'asemi-join': 
      //A anti-semij B solo lo schema di A
      if(!this.getParticipations().length==0) {
        this.setTempAttrs(innerj);
       }
      break;
      case 'difference': 
        if(!this.getParticipations().length==0) {
          this.setTempAttrs(innerj);
        }
        else{
          this.setTempAttrs(outerj);
        }
      break;
      case 'division': 
        if(!this.getParticipations().length==0) {
          for(let b in innerj){
            //console.log(innerj[b]);
            if(!outerj.some(s=>s.split('.')[1]==innerj[b].split('.')[1]))
              temp.push(innerj[b]);      
          }
        }
        this.setTempAttrs(temp);
      break;
      case 'union': 
        if(!this.getParticipations().length==0) {
          this.setTempAttrs(innerj);
        }
        else{
          this.setTempAttrs(outerj);
        }
      break;
      case 'intersection': 
        if(!this.getParticipations().length==0) {
          this.setTempAttrs(innerj);
        }
        else{
          this.setTempAttrs(outerj);
        }   
      break;
    } 
    this._inner = innerj;
  }
  canAddParticipation(entity) {
    if(entity.isEntity() && entity.getAttributes()==''){
      return { result: false, error: 'Define ' + entity.getName() + ' attributes schema before connecting' };     
    }
    if(entity.isRelationship() && entity.getTempAttrs()==undefined){
      return { result: false, error: 'Define the bottom schema before connecting' };     
    }
    if(entity.isRelationship() && entity.getTypeOfInPut()=='unaryPi' && entity.getSubmitted()==false ){
      if(entity.getTempAttrs()[0]!=undefined)
        return { result: false, error: 'Choose project attributes before' };     
    }
    if(!this.getParticipations().length==0){
      if(this._typeOfInput=='naturalJoin'){
        let schemaB= entity.isEntity() ? entity.getAttributes() : entity.getTempAttrs();
        //console.log(entity.getAttributes());

        /*for (let i in entity.getAttributes()){
          console.log(this.getOuter().map(e => e.split('.')[1]).indexOf(entity.getAttributes()[i].split('.')[1]));
         if (this.getOuter().map(e => e.split('.')[1]).indexOf(entity.getAttributes()[i].split('.')[1])!=-1){
          return { result: false, error: 'Naturaljoin' };  
         }
       }*/
        //console.log(this.getOuter());
        //console.log(schemaB);
       let trovato = false;
       for(let c in schemaB) {
        //console.log(schemaB[c]);
        //console.log(this.getOuter().map(a=>a.split('.')[1]).indexOf( schemaB[c].getName().split('.')[1])!=-1);
        if( entity.isEntity()  ){
          //return { result: false, error: 'Operator not applicable: no common attributes' };
          if(this.getOuter().map(a=>a.split('.')[1]).indexOf( schemaB[c].getName().split('.')[1])!=-1)
            trovato=true;
         
        }
        else {
          if(this.getOuter().map(a=>a.split('.')[1]).indexOf(schemaB[c].split('.')[1])!=-1)
          trovato=true;
        }
      }
      console.log(trovato + "trovato");
      if(trovato == false)
        return { result: false, error: 'Operator not applicable: no common attributes' };
        //outerj.map(e => e.split('.')[1]).indexOf(innerj[i])!=-1
    }
  }
    if(!this.getParticipations().length==0){
      if(this._typeOfInput=='difference' || this._typeOfInput=='intersection' || this._typeOfInput=='union' )  {
        let schemaB= entity.isEntity() ? entity.getAttributes() : entity.getTempAttrs();   
       // console.log(schemaB[0].getName());  
       // console.log(this.getOuter().getName());
       if(entity.isRelationship () && (entity.getTypeOfInPut() == 'unary' || entity.getTypeOfInPut() == 'unaryPi')){

        for(let c in schemaB) {
          if(!this.getOuter().some(a=>a.split('.')[1] == schemaB[c].split('.')[1])){
            return { result: false, error: 'Operator not applicable: incompatible schema' };
           
          }}
          for(let c in this.getOuter()) {
            //console.log(schemaB.map(e=>e.split('.')[1]));
           // console.log(this.getOuter()[c].split('.')[1]);
            if(!schemaB.map(e=>e.split('.')[1]).some(a=>a==this.getOuter()[c].split('.')[1])){
              return { result: false, error: 'Operator not applicable: incompatible schema' };
            }
          }
       }
       else{ 

        for(let c in schemaB) {
          if(!this.getOuter().some(a=>a.split('.')[1] == schemaB[c].getName().split('.')[1])){
            return { result: false, error: 'Operator not applicable: incompatible schema' };
           
          }}
          for(let c in this.getOuter()) {
          //  console.log(schemaB.map(e=>e.getName().split('.')[1]));
           // console.log(this.getOuter()[c].split('.')[1]);
            if(!schemaB.map(e=>e.getName().split('.')[1]).some(a=>a==this.getOuter()[c].split('.')[1])){
              return { result: false, error: 'Operator not applicable: incompatible schema' };
            }
          }
        }
      }     

     }

    if(!this.getParticipations().length==0){
      if(this._typeOfInput=='division'){
       // console.log(entity.isEntity());
        let schemaB= entity.isEntity() ? entity.getAttributes() : entity.getTempAttrs();   
        
       // console.log(this.getOuter().map(e=>e));
        for(let c in schemaB) {
          if(entity.isRelationship () && (entity.getTypeOfInPut() == 'unary' || entity.getTypeOfInPut() == 'unaryPi')){
            if(!this.getOuter().map(e=>e.split('.')[1]).some(a=>a == schemaB[c].split('.')[1]))
          return { result: false, error: 'Incompatible schema' };
         }
         else{
          if(!this.getOuter().map(e=>e.split('.')[1]).some(a=>a == schemaB[c].getName().split('.')[1]))
            return { result: false, error: 'Incompatible schema' };           
         }         
        } 
      }
    }
    //console.log(entity.getParticipations())
    if( entity instanceof Entity && !entity.getParticipations().length==0)
      return { result: false, error: 'The selected Table is already involved as input' };
    if(this._typeOfInput=='unary' || this._typeOfInput=='unaryPi'){
    if(!(entity instanceof Relationship) && !(entity instanceof Entity) )
      return { result: false, error: 'The selected item is not valid' };
    if(this.getParticipations().length == 2 && /*!this.isRecursive() &&*/ this.getParticipations().some(p => p.getEntity().getId() == entity.getId()))
      return { result: false, error: 'The entity selected to participate in the relationship is not a valid choice.' };
    
    if( entity instanceof Relationship && entity.getTypeOfInPut()=='result'&& !entity.getParticipations().length<0)
      return { result: false, error: 'The selected Table is already involved as input' };
    //console.log(this._model().getItemsWhere(i => i instanceof UnaryInput && i.getId() == entity.getId()).map(e=>e.getParticipationCount()));
    if(!(this._model().getItemsWhere(i => i instanceof Relationship && i.getId() == entity.getId()  ).map(e=>e.getParticipationCount())<=0))
      return { result: false, error: 'The selected Operator is already involved as output' };
    if(this._typeOfInput=='unary'){
      if(!(entity instanceof Entity) && !(entity instanceof Relationship && entity.getTypeOfInPut()=='unary'))
        return { result: false, error: 'The input of the SELECT operator could be only a TABLE or another SELECT operator' };
    }else if(this._typeOfInput=='unaryPi'){
      if(!(entity instanceof Entity) && (entity instanceof Relationship && entity.getTypeOfInPut()=='unaryPi'))
        return { result: false, error: 'Cannot use Projection as input of another Projection' };
    }
    return { result: true };
  }else if(this._typeOfInput == 'result') { //caso result
    //console.log("ho selezionato un Result");
    this.increaseParticipationCount();
    if(this._model().getItemsWhere(i => i instanceof Relationship && i.getTypeOfInPut()=='unary') && !(this._model().getItemsWhere(i => i instanceof Relationship && i.getId() == entity.getId()  ).map(e=>e.getParticipationCount())<=0))
      return { result: false, error: 'The selected Operator is already involved as output' }
    if(this._model().getItemsWhere(i => i instanceof Relationship && i.getTypeOfInPut()=='binary') && !(this._model().getItemsWhere(i => i instanceof Relationship && i.getId() == entity.getId()  ).map(e=>e.getParticipationCount())<=1))
      return { result: false, error: 'The selected Operator is already involved as output' }
    if(this.getParticipationCount()!=1)
      return { result: false, error: 'They could be only one result' }
    if(this._model().getItemsWhere(i => i instanceof Relationship && i.getTypeOfInPut()=='result') && (this._model().getItemsWhere(i => i instanceof Relationship && i.getId() == entity.getId()  ).map(e=>e.getParticipationCount()))>=1)
      return { result: false, error: 'The result can have only one connection' }
    //if(this.getTypeOfInPut()=='result' && this.getParticipationCount()>=1)
     // return {result: 'The result can have only one connection' };
    return { result: true };
    }
    else{ //if(this._typeOfInput == 'binary' || this.typeOfInput == 'naturalJoin'  || this.typeOfInput == 'semi-join' || this.typeOfInput == 'asemi-join' || this.typeOfInput == 'difference' || this.typeOfInput == 'division'){
      if(!(entity instanceof Relationship) && !(entity instanceof Entity) )
        return { result: false, error: 'The selected item is not valid' };
      if(this.getParticipations().length == 2 && /*!this.isRecursive() && */this.getParticipations().some(p => p.getEntity().getId() == entity.getId()))
        return { result: false, error: 'The entity selected to participate in the relationship is not a valid choice.' };
      if(this._model().getItemsWhere(i => i instanceof Relationship && i.getTypeOfInPut()=='unary') && !(this._model().getItemsWhere(i => i instanceof Relationship && i.getId() == entity.getId()  ).map(e=>e.getParticipationCount())<=0))
        return { result: false, error: 'The selected Operator is already involved as output' }
      if(!(this._model().getItemsWhere(i => {i instanceof Relationship && i.getTypeOfInPut()=='unary'})) && !(this._model().getItemsWhere(i => i instanceof Relationship && i.getId() == entity.getId()  ).map(e=>e.getParticipationCount())<=1))
        return { result: false, error: 'The selected Operator is already involved as output' }
      return { result: true };
    } 
  }
  getSupportedFunctionalities() {
    let rename=true;

    if(this._typeOfInput=='result' || this._typeOfInput=='naturalJoin' || this._typeOfInput=='division'|| this._typeOfInput=='difference' || this._typeOfInput=='union' || this._typeOfInput=='intersection')
      rename=false;
    
    let pi=false;
    if(this._typeOfInput=='unaryPi'){
      pi=true;
      rename = false;
    }    

    return {
      entityParticipation: true,
      attribute: false,
      moving: true,
      renaming: rename,
      translating: true,
      temporarySchema: false,
      renamingPi: pi,
      predicate: false,
      };
  }
  getAllowedFunctionalities() {
    let renaming = {ok: true};
    let entityParticipation = { ok: true };
    if(this._typeOfInput=='unary'||  this._typeOfInput=='unaryPi' ){
      if(this.getParticipations().length == 1)
        entityParticipation = { ok: false, error: 'This is a unary operator, you can connect only with one input' };}
        else if(this.getTypeOfInPut()=='result' && this.getParticipationCount()>=1){
          entityParticipation = { ok: false, error: 'The result can have only one connection' };
        }
      else{ //(this._typeOfInput!='unary'&&  this._typeOfInput!='unaryPi' && this.typeOfInput!='result'){
        if(this.getParticipations().length == 2)
          entityParticipation = { ok: false, error: 'This is a binary operator, you can connect only with two input' };
      }
     /* else{
        //result
        if(this.getParticipations().length == 1)
          entityParticipation = { ok: false, error: 'The result can have only one connection' };
      }*/
    //let attribute = { ok: true };
    let temporarySchema ={ok: false}
    let renamingPi = {ok: false}
    if(this._typeOfInput=='unaryPi'){
      renamingPi=true;
      renaming=false;
    }
    
    return {
     // attribute,
      entityParticipation,
      temporarySchema,
      renamingPi,
      renaming,
    };
  }
  getShowResult(){
    let showResult = { ok: true };
    if(this._participationCount >1)
    showResult = { ok: false, error: 'There could be only one result' };
    if(this._participationCount ==0)
      showResult={ ok: true };
    return showResult;
}
  getErrors() {
    let errors = [];
    if(this._typeOfInput=='binary' && this.getParticipations().length < 2)
      errors.push({ description: 'The binary operator must have two connections' });
    if(this._typeOfInput=='unary' && this.getParticipations().length < 1)
    errors.push({ description: 'The unary operator must have one connection' }); 
    return errors;
  }
  getWarnings() {
    return [];
  }
  __beforeDelete() {
  
    let n = this._model().getItemsWhere(i => i instanceof Relationship).length;
    if(this._model().getItemsWhere(i => i instanceof Relationship)[n-2])
      this._model().getItemsWhere(i => i instanceof Relationship)[n-2].decreaseParticipationCount();
  /*
    for (let i in this.getParticipations()){
     for (let j in this.getParticipations()[i].getRelationship().getParticipations()){
       this.getParticipations()[i].getRelationship().getParticipations()[j].getRelationship().decreaseParticipationCount();
     }
    }*/
    if (this.getTypeOfInPut=='reusult'){
        this.decreaseParticipationCount();
        this.getShowResult();
      }
    //console.log(this.getParticipationCount());
    /*for(let attribute of this.getAttributes())
      this._model().deleteItem(attribute.getId());*/

    for(let participation of this.getParticipations()){
      this._model().deleteItem(participation.getId());
    }
   // e.increaseParticipationCount();
  }
  setNamePredicate(name){
    this._name=name;
  }
  setName(name){
    this._objname = name;
    let row='';

    for(let n in this._objname){
      if(n!=this._objname.length-1){
      row+=this._objname[n].op + ' ' + this._objname[n].attrName + ' ' + this._objname[n].operator + ' ' + this._objname[n].attrName1 + ' ' + this._objname[n].condition  + ' ';
      }
      else{
      row+=this._objname[n].op + ' '+ this._objname[n].attrName + ' ' + this._objname[n].operator + ' ' + this._objname[n].attrName1 + ' ' + this._objname[n].condition  + ' ';
      }
    }
    this.setNamePredicate(row);
    return row;
  }
  setObjNameAttr(id,n) {
    return this._objname[id].attrName=n;
  }
  setObjNameAttr1(id,n) {
    return this._objname[id].attrName1=n;
  }
  setObjName(objnName){ 
    //the function changing the display of the predicate
    this._objname=objnName;
    let row='';
    if(this._typeOfInput == 'unaryPi'){
     
      row = this._temporaryAttrs;
      //for(let n in )
    }
    for(let n in objnName){
      if(n == 0 && objnName[0].op!="" ){
        row+=this._objname[n].attrName + ' ' + this._objname[n].operator + ' ' + this._objname[n].attrName1 + ' ' + this._objname[n].condition  + ' ';
      }
      else if(n!=this._objname.length-1 && n!=0){
      row+=objnName[n].op + ' ' + objnName[n].attrName + ' ' + objnName[n].operator + ' ' + objnName[n].attrName1 + ' ' + objnName[n].condition  + ' ';}
      else{
        row+=objnName[n].op + ' '+ this._objname[n].attrName + ' ' + this._objname[n].operator + ' ' + this._objname[n].attrName1 + ' ' + this._objname[n].condition  + ' ';
      }
    }
    this._name=row;
    return this._name;

  }
  displayName(name) { 
    if(!this._objname){
      return name;
    }
    else{
      let row='';
    for(let n in this._objname){
      if(n!=this._objname.length-1){
      row+= this._objname[n].op + ' ' +this._objname[n].attrName + ' ' + this._objname[n].operator + ' ' + this._objname[n].attrName1 + ' ' + this._objname[n].condition  + ' ' ;}
      else 
      row+=this._objname[n].attrName + ' ' + this._objname[n].operator + ' ' + this._objname[n].attrName1 + ' ' + this._objname[n].condition  + ' ';
    }
    this._name=row;
    return row;
  }
  } 

  move(dx, dy) {
    this._x = Math.round(this._x + dx);
    this._y = Math.round(this._y + dy);
  }
  toERCode() {
    let draw=this.getTypeToDraw();
    let code = this.getName()=='R'? `result ${this.getName()}` : this.getTypeOfInPut()=='asemi-join' ? `operator |⋉ ${this.getName()}` : `operator ${draw} ${this.getName()}`;

    if(this.hasParticipations()) {
      code += '\ninput: ';
      code += this.getParticipations().map(p => p.toERCode()).join(', ');
      code += '\n';
    }
    return code;
  }
}