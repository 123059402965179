<template>
  <table class="pi-predicate">
    <thead>
      <tr>
        <th>Attribute</th>
          <th>Select</th>
        </tr>    
    </thead>

    <tbody  v-if="erDesignStore.getSelectedItem().getObjTempAttrs()">
      <tr v-for="i in erDesignStore.getSelectedItem().getObjTempAttrs()" :key="i">
        <td>{{i._name}}</td>
        <td><input type="checkbox"  :checked='i.selected' @input='handleSelected($event, i)'  /></td>
      </tr>
    </tbody>  

    <tbody>
      <tr>
        <td><button class="button-reset" type='button' @click='handleReset($event)'>Reset</button></td>
        <td><button class="button-submit-pi" type='button' :disabled="this.submitted" @click='handleSubmit($event)'>submit</button></td>
      </tr>
    </tbody>
  </table>  
</template>

<script>
import { mainStore } from '../store/main';
import Model from '../lib/model'
export default {
   name: 'PiPredicate',
   components: {
    //
  },
  data() {
  return {
      model: new Model(),
      submitted: false,
      items: this.getSelectedItem().getObjTempAttrs(),
      first: true,
      reset: false,
      temp: [],
      }    
    },
  methods: {
    handleSelected(e, i){
      for (let j in this.items){
        if (this.items[j]==i)
          this.items[j].selected=e.target.checked;
      }
      //console.log(this.items);        
    },
    checkPrimaryKey(){
      if(this.item.primaryKey!='')
        return this.item.primaryKey;
      else
        return false;
      },
    handleAttrName(e){
      this.item.attrName=e.target.value;
      },
    handlePrimaryKey(e){
      this.item.primaryKey=e.target.checked;
      },
    handleNull(e){
      this.item.nullAttr=e.target.checked;
      },
    handleSubmit(e){    
      e.preventDefault();         
      this.getSelectedItem().setTempAttrs(this.items.filter(a=>a.selected==true).map(a=>a._name));
      this.erDesignStore.getSelectedItem().setObjName();
      this.submitted=true;
      this.getSelectedItem().setSubmitted();
      this.$forceUpdate();
      },

    handleReset(e){
      e.preventDefault();
      this.submitted=false;
      for (let j in this.items){
        this.items[j].selected=false;
        }
    }
   },
  setup() {
    const erDesignStore = mainStore.erDesign;
   
    function handleDelete(id){
      erDesignStore.deleteAttribute(id);   
    }
    function getList(){
      this.items=erDesignStore.getSelectedItem().getTempAttrsName();
      return this.items;
    }
    function setAttrs(){
      let temp= erDesignStore.getSelectedItem().getTempAttrsName().filter(e=> this.items.some(g=>g==e));
      erDesignStore.getSelectedItem().setTempAttrs(temp);
    }
    function setObjAttrs(){
      erDesignStore.getSelectedItem().setObjTempAttrs(this.items);
    }
    function getSelectedItem(){
      return erDesignStore.getSelectedItem();
    }
          
    return { 
      erDesignStore,
      getList,
      handleDelete,
      setAttrs,
      getSelectedItem,
      setObjAttrs,      
      }
  },
};
</script>

<style>
.pi-predicate {
  position: absolute;
  left: -10px;
  top: -12px;
  
  background-color: #f0f0f0; 
  padding: 5px; 
  border-radius: 4px; 
  border-spacing: 0 5px;   
  width: 250px;
}
th{
  font-family: 'Commissioner', sans-serif;
  font-size: 14px;
  font-weight: normal;

}

 td, th{
    padding-inline: 6px;
    position: relative;
    right: 50 px;
    text-anchor: middle;
     

  }
  tr{
    font-family: 'Commissioner', sans-serif;
    font-size: 13px;
    
  }
  #userText{
    width:130px;
    height: 13px;
  }
  button{
  font-family: 'Commissioner', sans-serif;
  background: #e6af2e;
  background-color: #e6af2e;
  border: #e6af2e;
  border-radius: 6px;
  font-size: 10px;
  font-weight: 400;

 
  }
  .button-submit-pi{
  position: absolute;
  left: 0px;
  top: 1px
  
}
.separator{
  height: 1px;
  margin: 6px 0;
  border-bottom: 1px dashed #ccc;
}
</style>

